<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">상담설정 관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="aspDataDropItem"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label=""
                placeholder="선택하세요"
                v-model="aspNewcustKey"
                v-on:change="getList"
            >
            <template v-slot:label>
              회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="box-wrap" v-if="isMainFrame">
      <div class="d-flex flex-wrap" >
        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            고객접수 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard1" @click="saveCard(1)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>고객접수 설정</dt>
              <dd>
                <v-radio-group v-model="card1.ROUTE_CD.CD" row hide-details>
                  <v-radio
                      name="ROUTE_CD"
                      label="고객접수시작"
                      value="START"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="ROUTE_CD"
                      label="고객접수중지"
                      value="STOP"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon> 고객 접수건을 상담사에게 배정하는 설정입니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>고객접수 제한건</dt>
              <dd>
                <v-text-field
                    class="form-inp sm"
                    name="ROUTE_WAITING_CNT"
                    v-model="card1.ROUTE_WAITING_CNT.CD"
                    type="number"
                    value="200"
                    min="0"
                    max="9999"
                ></v-text-field>
                <span >건 이상</span>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon> 고객 접수건이 설정된 건수 이상이 되면, 더이상 신규접수를 받지 않게 됩니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>이미지 수신여부</dt>
              <dd>
                <v-radio-group v-model="card1.RECEIVE_IMAGE_YN.CD" row hide-details>
                  <v-radio
                      name="RECEIVE_IMAGE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="RECEIVE_IMAGE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>고객이 전송한 이미지 수신여부를 설정합니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>장문 수신여부</dt>
              <dd>
                <v-radio-group v-model="card1.RECEIVE_LONG_TXT_YN.CD" row hide-details>
                  <v-radio
                      name="RECEIVE_LONG_TXT_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="RECEIVE_LONG_TXT_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>고객이 전송한 장문 수신여부를 설정합니다.
            </p>
          </div>
        </div>


        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            상담 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard2" @click="saveCard(2)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>상담가능 시간</dt>
              <dd>
                <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        name="WORK_START_TIME"
                        v-model="card2.WORK_START_TIME.CD"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar"
                        append-icon="svg-time"
                    >
                    </v-text-field>
                  </template>
                  <v-time-picker
                      v-model="card2.WORK_START_TIME.CD"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @click:minute="$refs.menu3.save()"
                  ></v-time-picker>
                </v-menu>
                ~
                <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        name="WORK_END_TIME"
                        v-model="card2.WORK_END_TIME.CD"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar"
                        append-icon="svg-time"
                    >
                    </v-text-field>
                  </template>
                  <v-time-picker
                      v-model="card2.WORK_END_TIME.CD"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @click:minute="$refs.menu4.save()"
                  ></v-time-picker>
                </v-menu>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon> 상담이 가능한 시간을 설정합니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>점심시간 사용여부 및 시간</dt>
              <dd>
                <v-radio-group v-model="card2.LUNCH_YN.CD" row hide-details class="radio-inline">
                  <v-radio
                      name="LUNCH_YN"
                      label="사용"
                      value="Y"
                      :on-icon="'svg-rad-on'"
                      :off-icon="'svg-rad-off'"
                  ></v-radio>
                  <div>
                    <v-menu
                        ref="menu1"
                        id="lunchStart"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="calendar-modal"
                        dark
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            name="LUNCH_START_TIME"
                            v-model="card2.LUNCH_START_TIME.CD"
                            :disabled="card2.LUNCH_YN.CD === 'N'"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp icon-calendar"
                            style="width: 100px"
                            append-icon="svg-time"
                        >
                        </v-text-field>
                      </template>
                      <v-time-picker
                          v-model="card2.LUNCH_START_TIME.CD"
                          :allowed-minutes="allowedStep"
                          color="#F7AFAE"
                          format="24hr"
                          @click:minute="$refs.menu1.save()"
                      ></v-time-picker>
                    </v-menu>
                    ~
                    <v-menu
                        ref="menu2"
                        id="lunchEnd"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="95px"
                        content-class="calendar-modal"
                        dark
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            name="LUNCH_END_TIME"
                            v-model="card2.LUNCH_END_TIME.CD"
                            :disabled="card2.LUNCH_YN.CD === 'N'"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            hide-details
                            class="form-inp icon-calendar"
                            style="width: 100px"
                            append-icon="svg-time"
                        >
                        </v-text-field>
                      </template>
                      <v-time-picker
                          v-model="card2.LUNCH_END_TIME.CD"
                          :allowed-minutes="allowedStep"
                          color="#F7AFAE"
                          format="24hr"
                          @click:minute="$refs.menu2.save()"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                  <v-radio
                      name="lunchTime"
                      label="미사용"
                      value="N"
                      class="ml-2"
                      :on-icon="'svg-rad-on'"
                      :off-icon="'svg-rad-off'"
                  ></v-radio>
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>점심시간으로 설정된 시간동안에는 접수를 받지 않습니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>상담사 자동인사 사용여부</dt>
              <dd>
                <v-radio-group v-model="card2.AUTO_GREETING_YN.CD" row hide-details>
                  <v-radio
                      name="AUTO_GREETING_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="AUTO_GREETING_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>채팅이 시작되면 자동인사 메시지가 고객에게 발송됩니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>상담허용수 일괄적용</dt>
              <dd>
                <v-radio-group v-model="card2.CONT_CHATAGENT_YN.CD" row hide-details>
                  <v-radio
                      name="CONT_CHATAGENT_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <div>
                    <v-text-field
                        class="form-inp sm"
                        name="CONT_CHATAGENT_CNT"
                        v-model="card2.CONT_CHATAGENT_CNT.CD"
                        :disabled="card2.CONT_CHATAGENT_YN.CD === 'N'"
                        type="number"
                        value="200"
                        min="0"
                        max="9999"
                    ></v-text-field>
                  </div>
                  <v-radio
                      name="CONT_CHATAGENT_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>관리자가 채팅허용수를 지정할 지를 설정합니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>제3자 채팅시작 알림</dt>
              <dd>
                <v-radio-group v-model="card2.CONSULT_ALRAM_YN.CD" row hide-details>
                  <v-radio
                      name="CONSULT_ALRAM_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="CONSULT_ALRAM_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>제3자 채팅 시작/종료 알림여부 설정합니다.
            </p>
          </div>
        </div>

        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            배분 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave3')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard3" @click="saveCard(3)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dd>
                <v-radio-group v-model="card3.SPEC_CNSL_ROUTE.CD" row hide-details>
                  <v-radio
                      name="SPEC_CNSL_ROUTE"
                      label="자동배분"
                      value="NORMAL"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="SPEC_CNSL_ROUTE"
                      label="전문상담만배분"
                      value="ONLY_SPEC"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon> <strong>자동배분</strong> : 인입된 고객 접수건을  자동으로 상담사에게 배분합니다.<br>
              <v-icon small>mdi-information-outline</v-icon><strong>전문상담만배분</strong> : 고객이 선택한 문의유형에 해당하는 전문상담사에게 배분합니다.<br>(전문상담만배분 설정은 구축형일때만 설정 가능합니다.)
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>직전상담사 배분 사용여부</dt>
              <dd>
                <v-radio-group v-model="card3.BEFORE_AGENT_USE_YN.CD" row hide-details>
                  <v-radio
                      name="BEFORE_AGENT_USE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="BEFORE_AGENT_USE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>직전에 상담한 이력이 있으면, 해당 상담사에게 배분합니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>직전상담사 배분 제한기간</dt>
              <dd>
                <v-radio-group v-model="card3.BEFORE_AGENT_PERIOD.CD" row hide-details>
                  <v-radio
                      name="BEFORE_AGENT_PERIOD"
                      label="1일"
                      value="1"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="BEFORE_AGENT_PERIOD"
                      label="3일"
                      value="3"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="BEFORE_AGENT_PERIOD"
                      label="없음"
                      value="0"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>직전에 상담한 이력이 있으면, 해당 상담사에게 배분합니다.
            </p>
          </div>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>지정상담사 배분 고객 문의유형 사용여부</dt>
              <dd>
                <v-radio-group v-model="card3.FIXED_ROUTE_INQRY_USE_YN.CD" row hide-details>
                  <v-radio
                      name="FIXED_ROUTE_INQRY_USE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="FIXED_ROUTE_INQRY_USE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>지정상담사 인입 시 고객 문의유형을 사용할지 여부 설정합니다.
            </p>
          </div>
        </div>

        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            문의유형 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave4')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard4" @click="saveCard(4)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>고객 문의유형</dt>
              <dd>
                <v-radio-group v-model="card4.INQRY_TYPE_YN.CD" row hide-details>
                  <v-radio
                      name="INQRY_TYPE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="INQRY_TYPE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>고객 접수 시, 문의유형을 선택하도록 합니다.
            </p>
            <dl class="dl-default">
              <dt>문의유형 사용레벨</dt>
              <dd>
                <v-text-field
                    class="form-inp sm"
                    name="INQRY_USE_LVL"
                    v-model="card4.INQRY_USE_LVL.CD"
                    type="number"
                    value="1"
                    min="1"
                    max="4"
                ></v-text-field>
                <span >레벨</span>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>사용레벨만큼의 문의유형이 고객에게 발송됩니다.
            </p>
          </div>
        </div>

        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            무응답 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave5')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard5" @click="saveCard(5)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>고객무응답 사용여부</dt>
              <dd>
                <v-radio-group v-model="card5.CUST_NORESP_USE_YN.CD" row hide-details>
                  <v-radio
                      name="CUST_NORESP_USE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="CUST_NORESP_USE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>고객이 무응답 시, 무응답 메시지를 고객에게 발송합니다.
            </p>
            <dl class="dl-default">
              <dt>고객무응답 후 종료</dt>
              <dd>
                <v-radio-group v-model="card5.CUST_NORESP_CHATEND.CD" row hide-details>
                  <v-radio
                      name="CUST_NORESP_CHATEND"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="CUST_NORESP_CHATEND"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>마지막 무응답 메시지 발송된 후, 자동으로 채팅을 종료할지에 대한 여부를 설정합니다.
            </p>
          </div>
        </div>

        <div class="setting-card">
          <h2 class="tit-h2 d-flex">
            기타 설정
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSave6')" outlined class="btn-point ml-auto mr-0" id="btnSaveCard6" @click="saveCard(6)" >저장</v-btn>
          </h2>
          <div class="mt-2">
            <dl class="dl-default">
              <dt>금칙어 적용여부</dt>
              <dd>
                <v-radio-group v-model="card6.PROHIBITE_APPLY_YN.CD" row hide-details>
                  <v-radio
                      name="PROHIBITE_APPLY_YN"
                      label="적용"
                      value="APPLY"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="PROHIBITE_APPLY_YN"
                      label="미적용"
                      value="NOTAPPLY"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>채팅 내용 중, 금칙어가 전환어로 변환되어 보여집니다.
            </p>
            <dl class="dl-default">
              <dt>자동응답메시지 사용여부</dt>
              <dd>
                <v-radio-group v-model="card6.AUTO_MESSAGE_YN.CD" row hide-details>
                  <v-radio
                      name="AUTO_MESSAGE_YN"
                      label="사용"
                      value="Y"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                  <v-radio
                      name="AUTO_MESSAGE_YN"
                      label="미사용"
                      value="N"
                      on-icon="svg-rad-on"
                      off-icon="svg-rad-off"
                  />
                </v-radio-group>
              </dd>
            </dl>
            <p class="text-caption">
              <v-icon small>mdi-information-outline</v-icon>업무시간 외, 휴일에 자동응답메시지가 발송됩니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialogM810401P01 from "./M810401P01.vue";
import {mixin} from "@/mixin/mixin";
import api from "@/store/apiUtil";

export default {
  name: "MENU_M810611", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810401P01,
  },
  mixins:[mixin],
  data() {
    return {
      isMainFrame: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],
      aspDataDropItem: [],

      aspNewcustKey: '',
      card1: {
        ROUTE_CD: {CD: '', NM: ''},
        ROUTE_WAITING_CNT: {CD: '', NM: ''},
        RECEIVE_IMAGE_YN: {CD: '', NM: ''},
        RECEIVE_LONG_TXT_YN: {CD: '', NM: ''},
      },
      card2: {
        WORK_START_TIME: {CD: '', NM: ''},
        WORK_END_TIME: {CD: '', NM: ''},
        LUNCH_YN: {CD: '', NM: ''},
        LUNCH_START_TIME: {CD: '', NM: ''},
        LUNCH_END_TIME: {CD: '', NM: ''},
        AUTO_GREETING_YN: {CD: '', NM: ''},
        CONT_CHATAGENT_YN: {CD: '', NM: ''},
        CONT_CHATAGENT_CNT: {CD: '', NM: ''},
        CONSULT_ALRAM_YN: {CD: '', NM: ''},
      },
      card3: {
        SPEC_CNSL_ROUTE: {CD: '', NM: ''},
        BEFORE_AGENT_USE_YN: {CD: '', NM: ''},
        BEFORE_AGENT_PERIOD: {CD: '', NM: ''},
        FIXED_ROUTE_INQRY_USE_YN: {CD: '', NM: ''},
      },
      card4: {
        INQRY_TYPE_YN: {CD: '', NM: ''},
        INQRY_USE_LVL: {CD: '', NM: ''},
      },
      card5: {
        CUST_NORESP_USE_YN: {CD: '', NM: ''},
        CUST_NORESP_CHATEND: {CD: '', NM: ''},
      },
      card6: {
        PROHIBITE_APPLY_YN: {CD: '', NM: ''},
        AUTO_MESSAGE_YN: {CD: '', NM: ''},
      },
    }
  },
  methods: {
    init(){
      this.aspDataDropItem = this.$store.getters['userStore/GE_USER_COMPANY'];
    },
    //aspNewcustKey
    async getList(){
      if( this.mixin_isEmpty(this.aspNewcustKey) ){
        this.common_alert("회사를 선택해 주세요.", "error");
        this.isMainFrame = false;
        return;
      }

      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/manage/inqire";
      requestData.headers["SERVICE"] = "chat.setting.manage";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.aspNewcustKey;

      let res = await this.common_postCall(requestData);
      this.getListCallBack(res);
    },
    getListCallBack(response){
      let data = response.DATA;
      // 한건도 없을 경우 초기화
      this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
      this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
      this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
      this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
      this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
      this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));

      this.isMainFrame = true;
      let intCnt = data.length;

      for(let i=0; i<intCnt; i++) {
        let strEnvCd = data[i].ENV_CD;				//설정코드값
        let strEnvCdNm = data[i].ENV_CD_NM;				//설정코드값
        let strEnvSetVal = data[i].ENV_SET_VALUE;	//설정값

        // card1
        let cardData = this.card1;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card2
        cardData = this.card2;
        for(let prop in cardData){
          if( prop === strEnvCd ){
            if( prop === "WORK_START_TIME" || prop === "WORK_END_TIME" || prop === "LUNCH_START_TIME" || prop === "LUNCH_END_TIME" ){
              // 시간 포맷 변경필요.
              cardData[prop].CD = strEnvSetVal.substr(0,2) + ":" + strEnvSetVal.substr(2,2);
            }else{
              cardData[prop].CD = strEnvSetVal;
            }
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card3
        cardData = this.card3;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card4
        cardData = this.card4;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card5
        cardData = this.card5;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card6
        cardData = this.card6;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }
      }


      // 한건도 없을 경우 초기화 진행 후 종료
      if(data.length < 1) {
        this.isMainFrame = true;
        this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
        this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
        this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
        this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
        this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
        this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));
        return;
      }

      for( let prop in this.card1 ){
        if(this.mixin_isEmpty(this.card1[prop].CD)){
          this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
          break;
        }
      }
      for( let prop in this.card2 ){
        if(this.mixin_isEmpty(this.card2[prop].CD)){
          this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
          break;
        }
      }
      for( let prop in this.card3 ){
        if(this.mixin_isEmpty(this.card3[prop].CD)){
          this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
          break;
        }
      }
      for( let prop in this.card4 ){
        if(this.mixin_isEmpty(this.card4[prop].CD)){
          this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
          break;
        }
      }
      for( let prop in this.card5 ){
        if(this.mixin_isEmpty(this.card5[prop].CD)){
          this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
          break;
        }
      }
      for( let prop in this.card6 ){
        if(this.mixin_isEmpty(this.card6[prop].CD)){
          this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));
          break;
        }
      }
    },
    saveCard(num){
      let msg = "";
      if(num === 1){
        msg = "고객접수 설정 ";
      }else if(num === 2){
        msg = "상담 설정 ";
      }else if(num === 3){
        msg = "배분 설정 ";
      }else if(num === 4){
        msg = "문의유형 설정 ";
      }else if(num === 5){
        msg = "무응답 설정 ";
      }else if(num === 6){
        msg = "기타 설정 ";
      }
      msg += "수정하시겠습니까?";
      this.common_confirm(msg, async () => {
        await this.saveCardYesCall(num);
       }, null, null, null, "chk");
    },
    saveCardYesCall(num){
      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/manage/modify";
      requestData.headers["SERVICE"] = "chat.setting.manage";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      let sendDataList = [];

      // card 넘버로 데이터 구분하여 바로 처리.
      let cardData = eval("this.card" + num);
      //let cardData = this.card1;
      let cnt = 0;
      for( let prop in cardData ){
        let obj = {};
        if(cnt == 0){
          obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
          obj["PROC_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
        }
        obj["ENV_CD"]= prop;
        obj["ENV_CD_NM"]= cardData[prop].NM;
        if(num === 2){
          obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
          if( prop === "WORK_START_TIME" || prop === "WORK_END_TIME" || prop === "LUNCH_START_TIME" || prop === "LUNCH_END_TIME" ){
            obj["ENV_SET_VALUE"]= cardData[prop].CD.replace(":","");
          }else{
            obj["ENV_SET_VALUE"]= cardData[prop].CD;
          }
        }else{
          obj["ENV_SET_VALUE"]= cardData[prop].CD;
        }
        sendDataList.push(obj);
        cnt++;
      }
      requestData.sendData = {
        list : sendDataList
      };
      this.submitCall(requestData);
    },
    async submitCall(requestData){
      const res = await this.common_postCall(requestData);
      this.saveCardCallBack(res);
    },
    saveCardCallBack(res){
      if( this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
        return;
      }
      this.common_alert("정상적으로 수정되었습니다.");
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },
    unlockDialog() {
      console.log("unlock");
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    defaultVO() {
      return {
        "card1": {
          "ROUTE_CD": {
            "CD": "START",
            "NM": "분배상태코드"
          },
          "ROUTE_WAITING_CNT": {
            "CD": "100",
            "NM": "분배대기인원"
          },
          "RECEIVE_IMAGE_YN": {
            "CD": "Y",
            "NM": "이미지 수신여부"
          },
          "RECEIVE_LONG_TXT_YN": {
            "CD": "Y",
            "NM": "장문 수신여부"
          }
        },
        "card2": {
          "WORK_START_TIME": {
            "CD": "09:00",
            "NM": "상담시작시간"
          },
          "WORK_END_TIME": {
            "CD": "18:00",
            "NM": "상담종료시간"
          },
          "LUNCH_YN": {
            "CD": "Y",
            "NM": "점심시간 사용여부"
          },
          "LUNCH_START_TIME": {
            "CD": "12:00",
            "NM": "점심시작시간"
          },
          "LUNCH_END_TIME": {
            "CD": "13:00",
            "NM": "점심종료시간"
          },
          "AUTO_GREETING_YN": {
            "CD": "Y",
            "NM": "상담사자동인사사용여부"
          },
          "CONT_CHATAGENT_YN": {
            "CD": "Y",
            "NM": "상담허용수일괄적용"
          },
          "CONT_CHATAGENT_CNT": {
            "CD": "10",
            "NM": "상담허용수일괄적용값"
          },
          "CONSULT_ALRAM_YN": {
            "CD": "Y",
            "NM": "제 3자채팅알림 여부"
          }
        },
        "card3": {
          "SPEC_CNSL_ROUTE": {
            "CD": "NORMAL",
            "NM": "전문상담만배분"
          },
          "BEFORE_AGENT_USE_YN": {
            "CD": "Y",
            "NM": "직전상담사 배분 사용여부"
          },
          "BEFORE_AGENT_PERIOD": {
            "CD": "1",
            "NM": "직전상담사 배분 기간  "
          },
          "FIXED_ROUTE_INQRY_USE_YN": {
            "CD": "Y",
            "NM": "지정상담사 배분 고객문의유형 사용여부 "
          }
        },
        "card4": {
          "INQRY_TYPE_YN": {
            "CD": "Y",
            "NM": "고객문의유형"
          },
          "INQRY_USE_LVL": {
            "CD": "4",
            "NM": "문의유형 사용레벨"
          }
        },
        "card5": {
          "CUST_NORESP_USE_YN": {
            "CD": "Y",
            "NM": "고객무응답메시지사용여부"
          },
          "CUST_NORESP_CHATEND": {
            "CD": "Y",
            "NM": "고객무응답메시지전송후채팅종료"
          }
        },
        "card6": {
          "PROHIBITE_APPLY_YN": {
            "CD": "APPLY",
            "NM": "금칙어사용여부"
          },
          "AUTO_MESSAGE_YN": {
            "CD": "Y",
            "NM": "자동응답메시지사용여부"
          }
        }
      }
    },
  },
  created() {
    this.init();
  }
};
</script>

<style></style>
